import Footer from './components/Footer';
import Header from './components/Header';
import useClevertap from './hooks/useClevertap';
import HomePage from './screens/Homepage';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import React from 'react';
import './index.css';

function App() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  if (process.env.REACT_APP_DATABASE_URL) {
    firebaseConfig.databaseURL = process.env.REACT_APP_DATABASE_URL;
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const auth = getAuth(app);
  const db = getFirestore(app);
  // clevertap event push
  const { handleEventPushClick } = useClevertap();

  
  return (
    <div className="w-screen h-screen">
      
      <HomePage />
     
    </div>

  );
}

export default App;
