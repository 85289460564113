import React, { useEffect, useState, useRef } from 'react'
import '../../index.css'
import { generateUsername } from 'friendly-username-generator'
import { petTypeConstant, petBehaviourConstant } from '../../constants/dataConstant'
import BGHome from "../../assets/images/BgHome.png"
import BGImage2 from "../../assets/images/BGImage2.png"
import ButtonElement from '../../components/ButtonElement'
import Dog from "../../assets/images/Dog.png"
import Cat from "../../assets/images/Cat.png"
import Fish from "../../assets/images/Fish.png"
import Rabbit from "../../assets/images/Rabbit.png"
import Others from "../../assets/images/Others.png"
import DogCurious from "../../assets/images/DogCurious.png"
import DogScared from "../../assets/images/DogScared.png"
import DogEnergetic from "../../assets/images/DogEnergetic.png"
import DogSocial from "../../assets/images/DogSocial.png"
import CatCurious from "../../assets/images/CatCurious.png"
import CatScared from "../../assets/images/CatScared.png"
import CatEnergetic from "../../assets/images/CatEnergetic.png"
import CatSocial from "../../assets/images/CatSocial.png"
import FishCurious from "../../assets/images/FishCurious.png"
import FishScared from "../../assets/images/FishScared.png"
import FishSocial from "../../assets/images/FishSocial.png"
import FishEnergetic from "../../assets/images/FishEnergetic.png"
import RabbitCurious from "../../assets/images/RabbitCurious.png"
import RabbitEnergetic from "../../assets/images/RabbitEnergetic.png"
import RabbitScared from "../../assets/images/RabbitScared.png"
import RabbitSocial from "../../assets/images/RabbitSocial.png"
import OthersCurious from "../../assets/images/OthersCurious.png"
import OthersEnergetic from "../../assets/images/OthersEnergetic.png"
import OthersScared from "../../assets/images/OthersScared.png"
import OthersSocial from "../../assets/images/OthersSocial.png"
import Forward from "../../assets/images/Forward.png"
import Backward from "../../assets/images/Backward.png"
import Lottie from "lottie-react";
import petPlaylistAnimation from "../../assets/animations/Animation - 1731334310172.json"
import petCowAnimation from "../../assets/animations/Animation - 1731334342148.json"
import Dekstop from './Dekstop'
import Mobile from './Mobile'
import useLogin from '../../hooks/useLogin'
import ShareModal from '../../components/Share'

const playersData = [
  {
    "image": Dog,
    "pet": "DOG",
    "behaviours": [
      { "behaviour": "HAPPY", "image": Dog },
      { "behaviour": "CALM", "image": Dog },
      { "behaviour": "ENERGETIC", "image": DogEnergetic },
      { "behaviour": "SOCIAL", "image": DogSocial },
      { "behaviour": "SCARED", "image": DogScared },
      { "behaviour": "CURIOUS", "image": DogCurious },
    ]
  },
  {
    "image": Cat,
    "pet": "CAT",
    "behaviours": [
      { "behaviour": "HAPPY", "image": Cat },
      { "behaviour": "CALM", "image": Cat },
      { "behaviour": "ENERGETIC", "image": CatEnergetic },
      { "behaviour": "SOCIAL", "image": CatSocial },
      { "behaviour": "SCARED", "image": CatScared },
      { "behaviour": "CURIOUS", "image": CatCurious },
    ]
  },
  {
    "image": Fish,
    "pet": "FISH",
    "behaviours": [
      { "behaviour": "HAPPY", "image": Fish },
      { "behaviour": "CALM", "image": Fish },
      { "behaviour": "ENERGETIC", "image": FishEnergetic },
      { "behaviour": "SOCIAL", "image": FishSocial },
      { "behaviour": "SCARED", "image": FishScared },
      { "behaviour": "CURIOUS", "image": FishCurious },
    ]
  },
  {
    "image": Rabbit,
    "pet": "RABBIT",
    "behaviours": [
      { "behaviour": "HAPPY", "image": Rabbit },
      { "behaviour": "CALM", "image": Rabbit },
      { "behaviour": "ENERGETIC", "image": RabbitEnergetic },
      { "behaviour": "SOCIAL", "image": RabbitSocial },
      { "behaviour": "SCARED", "image": RabbitScared },
      { "behaviour": "CURIOUS", "image": RabbitCurious },
    ]
  },
  {
    "image": Others,
    "pet": "OTHERS",
    "behaviours": [
      { "behaviour": "HAPPY", "image": Others },
      { "behaviour": "CALM", "image": Others },
      { "behaviour": "ENERGETIC", "image": OthersEnergetic },
      { "behaviour": "SOCIAL", "image": OthersSocial },
      { "behaviour": "SCARED", "image": OthersScared },
      { "behaviour": "CURIOUS", "image": OthersCurious },
    ]
  }
]
const HomePage = () => {
  const { handleGoogleSignIn, googleLogout, accessToken, uuid,
    setUserCreated, userFirstName, userInfo, username } = useLogin()

  const [isOpen, setIsOpen] = useState(false);
  const [petName, setPetName] = useState('')
  const [petType, setPetType] = useState(petTypeConstant[0])
  const [petBehaviour, setPetBehaviour] = useState(petBehaviourConstant[0])
  const [typeOfPlaylist, setTypeOfPlaylist] = useState('')
  const [generatedPlaylist, setGeneratedPlaylist] = useState([])
  const [playlistLink, setPlaylistLink] = useState('')
  const [isGenerating, setIsGenerating] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [level, setLevel] = useState(0)
  const [activePetIndex, setActivePetIndex] = useState(0);
  const [activeBehaviorIndex, setActiveBehaviorIndex] = useState(0);
  const [gameStart, setGameStart] = useState("ready")
  const currentPet = playersData[activePetIndex];
  const currentBehavior = currentPet.behaviours[activeBehaviorIndex];
  const [reveal, setReveal] = useState(false)

  const createPlaylistRef = useRef<HTMLDivElement>(null)
  const iframeRef = useRef<HTMLDivElement>(null)

  const generatePlaylistFromOpenAI = async () => {
    setIsGenerating(true)
    try {
      const response = await fetch('https://cruncher.asset.money/pet/generate-playlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          pet_name: petName,
          pet_type: currentPet,
          pet_behaviour: currentBehavior,
          type_of_playlist: typeOfPlaylist
        })
      })

      if (!response.ok) {
        throw new Error('Failed to generate playlist from OpenAI')
      }

      const data = await response.json()
      // console.log('Playlist generated:', data)
      setGeneratedPlaylist(data?.playlist)
    } catch (error) {
      console.error('Error generating playlist from OpenAI:', error)
    } finally {
      setIsGenerating(false)
    }
  }

  const handleShare = () => {
    if (accessToken) {
      setIsOpen(true);
    }
  }

  const handleNextPet = () => {
    setActivePetIndex((prevIndex) => (prevIndex + 1) % playersData.length);
    setActiveBehaviorIndex(0); // Reset behavior index for the new pet
  };

  const handlePreviousPet = () => {
    setActivePetIndex((prevIndex) =>
      prevIndex === 0 ? playersData.length - 1 : prevIndex - 1
    );
    setActiveBehaviorIndex(0); // Reset behavior index for the new pet
  };

  const handleNextBehavior = () => {
    setActiveBehaviorIndex((prevIndex) =>
      (prevIndex + 1) % currentPet.behaviours.length
    );
  };

  const handlePreviousBehavior = () => {
    setActiveBehaviorIndex((prevIndex) =>
      prevIndex === 0 ? currentPet.behaviours.length - 1 : prevIndex - 1
    );
  };

  const createPlaylist = async () => {
    setIsCreating(true)
    try {
      let username = ''

      const usernameFromLocalStorage = localStorage.getItem('username')
      if (!usernameFromLocalStorage) {
        username = generateUsername()
        localStorage.setItem('username', username)
      } else {
        username = usernameFromLocalStorage
      }

      const response = await fetch('https://cruncher.asset.money/pet/create-playlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: username,
          playlist_description: `${petName} is a ${petType} and is ${petBehaviour}.`,
          pet_name: petName,
          pet_type: petType,
          pet_behaviour: petBehaviour,
          type_of_playlist: typeOfPlaylist,
          playlist: generatedPlaylist
        })
      })

      if (!response.ok) {
        throw new Error('Failed to create playlist')
      }

      const data = await response.json()
      console.log('Playlist created:', data)
      let link = data?.createdPlaylist?.external_urls?.spotify
      setTimeout(() => {
        setPlaylistLink(link)
      }, 1500)
    } catch (error) {
      console.error('Error creating playlist:', error)
    } finally {
      setIsCreating(false)
    }
  }

  const handleSubmit = async () => {
    await generatePlaylistFromOpenAI()

    setTimeout(() => {
      createPlaylistRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  const handleCreatePlaylist = async () => {
    const nextlevel = level
    setLevel(nextlevel + 1)
    await createPlaylist()
  }

  const handleNextLevel = () => {
    const nextlevel = level
    if (level === 4) {
      handleSubmit()
    }
    setLevel(nextlevel + 1)
  }

  // Add this useEffect to handle scrolling after playlist link is set
  useEffect(() => {
    if (playlistLink) {
      setTimeout(() => {
        iframeRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }, [playlistLink])

  const handleNextStep = () => {
    setGameStart("set");
    setLevel(1)

    setTimeout(() => {
      setGameStart("go");
    }, 2000);
  };

  const handleSignIn = async () => {

    try {
      const check = await handleGoogleSignIn()
      console.log("checing", check)
      if (accessToken) { setReveal(false) }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    if (!accessToken) {
      setReveal(true);
      console.log("Access token missing:", accessToken);
    } else {
      setReveal(false);
    }
  }, [accessToken]);
  
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className='md:hidden h-screen'>
        <Mobile typeOfPlaylist={typeOfPlaylist} setLevel={setLevel} handleShare={handleShare} accessToken={accessToken} handleSignIn={handleSignIn} reveal={reveal} isCreating={isCreating} playlistLink={playlistLink} iframeRef={iframeRef} handleCreatePlaylist={handleCreatePlaylist} gameStart={gameStart} handleNextStep={handleNextStep} generatedPlaylist={generatedPlaylist} level={level} petName={petName} currentPet={currentPet} isGenerating={isGenerating} setPetName={setPetName} handlePreviousPet={handlePreviousPet} handleNextPet={handleNextPet} currentBehavior={currentBehavior} handlePreviousBehavior={handlePreviousBehavior} handleNextBehavior={handleNextBehavior} setTypeOfPlaylist={setTypeOfPlaylist} handleNextLevel={handleNextLevel} />
      </div>
      <div className='hidden md:block h-screen'>
        <Dekstop typeOfPlaylist={typeOfPlaylist} setLevel={setLevel} handleShare={handleShare} accessToken={accessToken} handleSignIn={handleSignIn} reveal={reveal} isCreating={isCreating} playlistLink={playlistLink} iframeRef={iframeRef} handleCreatePlaylist={handleCreatePlaylist} gameStart={gameStart} handleNextStep={handleNextStep} generatedPlaylist={generatedPlaylist} level={level} petName={petName} currentPet={currentPet} isGenerating={isGenerating} setPetName={setPetName} handlePreviousPet={handlePreviousPet} handleNextPet={handleNextPet} currentBehavior={currentBehavior} handlePreviousBehavior={handlePreviousBehavior} handleNextBehavior={handleNextBehavior} setTypeOfPlaylist={setTypeOfPlaylist} handleNextLevel={handleNextLevel} />
      </div>
      {isOpen && (
        <ShareModal
          shareUrl={playlistLink}
          artistName={""}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default HomePage
