import React from 'react'
import BGHome from "../../assets/images/BgHome.png"
import BGImage2 from "../../assets/images/BGImage2.png"
import ButtonElement from '../../components/ButtonElement'
import Forward from "../../assets/images/Forward.png"
import Backward from "../../assets/images/Backward.png"
import Lottie from "lottie-react";
import petPlaylistAnimation from "../../assets/animations/Animation - 1731334310172.json"
import petCowAnimation from "../../assets/animations/Animation - 1731334342148.json"
import BgHome3 from "../../assets/images/BgHome3.png"
import petPlaylistAnimation2 from "../../assets/animations/Animation - 1731468816638.json"

type currentBehaviorProps = {
  behaviour: string, image: string
}

type currentPetProps = {
  image: string;
  pet: string;
  behaviours: currentBehaviorProps[];
}

type DesktopProps = {
  typeOfPlaylist: string,
  setLevel: (level: number) => void,
  handleShare: () => void,
  accessToken: string,
  reveal: boolean,
  isCreating: boolean,
  playlistLink: string,
  iframeRef: React.RefObject<HTMLDivElement>;
  handleCreatePlaylist: () => void,
  gameStart: boolean | string; // or another specific type if you have it
  handleNextStep: () => void;
  generatedPlaylist: string[]; // assuming it’s an array of song names (strings)
  level: number;
  petName: string;
  currentPet: currentPetProps; // adjust to the specific type of `currentPet`
  isGenerating: boolean;
  setPetName: (name: string) => void;
  handlePreviousPet: () => void;
  handleNextPet: () => void;
  currentBehavior: currentBehaviorProps; // adjust based on the specific type of `currentBehavior`
  handlePreviousBehavior: () => void;
  handleNextBehavior: () => void;
  setTypeOfPlaylist: (type: string) => void;
  handleNextLevel: () => void;
  handleSignIn: () => Promise<void>
};
{/* <CopyToClipboard text={`${shareLink}&utm_source=direct&utm_medium=direct_link&utm_campaign=direct_copy`} onCopy={() => setCopyLink(true)}>
              <span className="share-link-copy-btn">
                {copyLink ? "Copied" : "Copy"}
              </span>
            </CopyToClipboard> */}

const Dekstop = ({ typeOfPlaylist, setLevel, handleShare, accessToken, handleSignIn, reveal, isCreating, playlistLink, iframeRef, handleCreatePlaylist, gameStart, handleNextStep, generatedPlaylist, level, petName, currentPet, isGenerating, setPetName, handlePreviousPet, handleNextPet, currentBehavior, handlePreviousBehavior, handleNextBehavior, setTypeOfPlaylist, handleNextLevel }: DesktopProps) => {
  return (
    <div className='flex flex-col min-h-screen w-full items-center justify-center'
      style={{
        backgroundImage: `url(${BGHome})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className={`flex flex-col items-center justify-center w-[1383px] h-[650px]`}
        style={{
          backgroundImage: level === 5 || level === 6 ? `url(${BgHome3})` : `url(${BGImage2})`,
          backgroundSize: "contain",  // Ensures the entire image fits within the div
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {gameStart === "ready" ?
          <div className='flex flex-col w-full items-center gap-10 justify-around py-10'>
            <p className="font-[joystix] text-center px-56 text-[#BE94FB] text-[50px] text-outline"
              style={
                {
                  '--outline-width': '1.2px',
                  '--outline-color': '#D2D2FF'
                } as React.CSSProperties
              }
            >welcome to petplaylist</p>
            <p className="font-[joystix] text-center px-[300px] text-[#FFFFFF] text-[28px] text-outline"
              style={
                {
                  '--outline-width': '1.2px',
                  '--outline-color': '#5A3D80'
                } as React.CSSProperties
              }
            >let’s create a personalised playlist for your pet</p>
            <div className='w-[278px] h-[62px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
              <div className='w-[270px] h-[60px] justify-center flex rounded-b-[6px] bg-[#816D93]'> {/* Apply negative margin */}
                <div className='w-[266px] h-[57px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                  <button
                    className='text-outline text-[29px] text-[#FFFFFF] w-[260px] h-[51px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#D4D3E1]'
                    onClick={handleNextStep}
                    style={
                      {
                        '--outline-width': '1.2px',
                        '--outline-color': '#5A3D80'
                      } as React.CSSProperties
                    }
                  >
                    let’s go
                  </button>
                </div>
              </div>
            </div>

          </div>
          : gameStart === "set" ?
            // <div className="flex flex-row items-center justify-center space-x-0">
            // {/* <Lottie className="h-3/5" animationData={petPlaylistAnimation} loop={true} /> */}
            <Lottie className="h-3/5" animationData={petCowAnimation} loop={true} />
            // {/* </div> */}
            : level === 5
              ? (generatedPlaylist?.length ? <div className='flex flex-col items-center'>
                <p className="pt-7 pb-3 font-[joystix] text-[#BE94FB] text-[32px] text-outline"
                  style={
                    {
                      '--outline-width': '1.2px',
                      '--outline-color': '#5A3D80'
                    } as React.CSSProperties
                  }
                >{petName}'s PLAYLIST</p>
                <div className='flex flex-row items-start gap-7'>
                  <div className='flex flex-col items-center gap-2'>
                    <div className='flex flex-col items-center justify-center w-[325px] h-[380px] border rounded-[10px] border-[#D9DFEB] bg-[#98B5FD]'>

                      <div className='w-[313px] mt-2 h-[360px] border rounded-[10px] border-[#FFFDFD] bg-[#B283FF]'>
                        <div className='flex flex-col items-center justify-between py-4 -mt-2 w-[307px] h-[351px] border rounded-[10px] bg-[#262638]'>
                          <img src={currentPet.image} alt={currentPet.pet} className="w-[170.44px] h-[160.28px]" />
                          <p
                            className="font-[joystix] text-[#FFFFFF] leading-5 text-center text-[16px] text-outline"
                            style={{
                              '--outline-width': '1.2px',
                              '--outline-color': '#5E5877'
                            } as React.CSSProperties}
                          >
                            This playlist features calming and soothing songs that will help {petName} relax and unwind from her energetic behavior.
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      className="font-[joystix] text-[#262638] text-[16px] text-outline leading-7"
                      style={{
                        '--outline-width': '1.2px',
                        '--outline-color': '#B4A2FF'
                      } as React.CSSProperties}
                    >
                      GET THIS PLAYLIST ON
                    </p>

                    <div className='w-[168px] h-[49px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                      <div className='w-[160px] h-[46px] justify-center flex rounded-b-[6px] bg-[#816D93]'> {/* Apply negative margin */}
                        <div className='w-[156px] h-[43px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                          <button
                            className='text-outline text-[23px] text-[#FFFFFF] w-[160px] h-[45px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#9FE870]'
                            onClick={handleCreatePlaylist}
                            style={{
                              '--outline-width': '1.2px',
                              '--outline-color': '#5A3D80'
                            } as React.CSSProperties}

                          >
                            SPOTIFY
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='w-[268px] h-[49px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                      <div className='w-[260px] h-[46px] justify-center flex rounded-b-[6px] bg-[#816D93]'>
                        <div className='w-[256px] h-[43px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                          <button
                            className='text-outline text-[16px] text-[#FFFFFF] w-[260px] h-[45px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#d1c5c7]'

                            onClick={() => setLevel(4)}
                            style={{
                              '--outline-width': '1.2px',
                              '--outline-color': '#5A3D80'
                            } as React.CSSProperties}
                          >
                            Generate a new playlist
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex flex-col items-center justify-center w-[556px] h-[507px] border rounded-[10px] border-[#D9DFEB] bg-[#98B5FD]'>
                    <div className='w-[524.09px] mt-2 h-[493.78px] border rounded-[10px] border-[#FFFDFD] bg-[#B283FF]'>
                      <div className='flex flex-col -mt-2 items-center justify-between w-[514.87px] h-[494.87px] border rounded-[10px] bg-[#262638]'>
                        {generatedPlaylist.length > 0 && (
                          <>
                            <ul className='rounded-lg shadow-md p-4 overflow-y-auto h-[494px]'>
                              {generatedPlaylist.slice(0, generatedPlaylist.length).map((song: any, index: number) => (
                                <li
                                  className="font-[joystix] text-[#FFFFFF] text-[20px] text-outline leading-7"
                                  key={index}
                                  style={{
                                    '--outline-width': '1.2px',
                                    '--outline-color': '#5A3D80'
                                  } as React.CSSProperties}
                                >
                                  {song}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </div>


                </div>
              </div> : isGenerating ? <Lottie className="h-3/5" animationData={petPlaylistAnimation} loop={true} /> : "")
              : level === 6 ? (isCreating ? <Lottie className="h-3/5" animationData={petPlaylistAnimation2} loop={true} /> :

                playlistLink && (
                  <div ref={iframeRef} className='mt-8 w-full flex gap-7 flex-col items-center justify-center'>
                    <h2 className='text-2xl font-[joystix] font-bold text-white mb-4'>Your Spotify Playlist</h2>
                    <div className='relative flex items-center justify-center w-full' style={{ width: '60%' }}>
                      {/* Blurred iframe section */}
                      <div
                        className={`${reveal ? 'backdrop-blur-md' : ''} relative w-full h-[380px] flex items-center justify-center`}
                        style={{
                          filter: reveal ? 'blur(10px)' : 'none', // Optional extra for more control
                          transition: 'filter 0.3s ease-in-out',
                        }}
                      >
                        <iframe
                          src={`https://open.spotify.com/embed/playlist/${playlistLink.split('/').pop()}`}
                          width='100%'
                          height='100%'
                          frameBorder='0'
                          allowTransparency={true}
                          allow='encrypted-media'
                          title='Spotify Playlist'
                        ></iframe>
                      </div>

                      {/* Reveal button overlay */}
                      {reveal && (
                        <button
                          onClick={handleSignIn}

                          className="absolute bg-[#BE94FB] text-white text-[20px] font-[joystix] w-[300px] h-[50px] rounded-[10px] font-bold"
                          style={{ top: '50%', transform: 'translateY(-50%)' }}
                        >
                          Reveal 🐾
                        </button>
                      )}
                    </div>
                    <div className='flex flex-row items-center justify-between gap-5'>
                      {accessToken && <ButtonElement disabled={!petName} name={"SHARE"} onClick={handleShare} />}
                      <div className='w-[388px] h-[49px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                        <div className='w-[380px] h-[46px] justify-center flex rounded-b-[6px] bg-[#816D93]'>
                          <div className='w-[376px] h-[43px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                            <button
                              className='text-outline text-[18px] text-[#FFFFFF] w-[370px] h-[45px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#D4D3E1]'

                              onClick={() => setLevel(4)}
                              style={{
                                '--outline-width': '1.2px',
                                '--outline-color': '#5A3D80'
                              } as React.CSSProperties}
                            >
                              Generate a new playlist
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )

              )
                : level < 5 && level >= 1 ?
                  <div className='flex flex-col items-center justify-center w-[435px] h-[528px] border rounded-[10px] border-[#D9DFEB] bg-[#98B5FD]'>

                    <div className='w-[411.99px] mt-2 h-[502.99px] border rounded-[10px] border-[#FFFDFD] bg-[#B283FF]'>
                      <div className='py-12 flex flex-col -mt-2 items-center justify-between w-[407px] h-[501px] border rounded-[10px] bg-[#262638]'>


                        <div className='flex flex-col items-center justify-center'>
                          <p className="font-[joystix] text-[#BE94FB] text-[22px] text-outline"
                            style={
                              {
                                '--outline-width': '1.2px',
                                '--outline-color': '#5A3D80'
                              } as React.CSSProperties
                            }
                          >{level}/4</p>
                          <p
                            className=" text-center font-[joystix] text-[29px] text-[#FFFFFF] text-outline"
                            style={
                              {
                                '--outline-width': '1.2px',
                                '--outline-color': '#5E5877'
                              } as React.CSSProperties
                            }
                          >
                            {level === 1 ? "your pet’s name" : level === 2 ? "SELECT PLAYER" : level === 3 ? "Behaviour" : "type of playlist"}
                          </p>
                        </div>

                        {level === 1 ?
                          <div className='w-[365px] h-[52px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                            <div className='w-[359.78px] h-[53px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                              <input
                                className='text-outline font-[joystix] text-center text-[#FFFFFF] text-[18px] w-[365px] h-[55px] bg-[#D4D3E1] rounded-[6px] border-[3.2px] border-[#918AAE] placeholder:text-[#FFFFFF]'
                                style={
                                  {
                                    '--outline-width': '1.2px',
                                    '--outline-color': '#5A3D80'
                                  } as React.CSSProperties
                                }
                                onChange={(e) => setPetName(e.target.value)}
                                placeholder='TYPE HERE'>
                              </input>
                            </div>
                          </div> : level === 2 ? (
                            <div className="flex items-center justify-between w-full px-10">
                              <img src={Backward} className="cursor-pointer w-[25px] h-[36px]" onClick={handlePreviousPet} />
                              <div className='flex flex-col items-center justify-center gap-5'>
                                <img src={currentPet.image} alt={currentPet.pet} className="w-[205px] h-[198px]" />
                                <p className="text-center pb-4 font-[joystix] text-[18px] text-[#FFFFFF] text-outline">
                                  {currentPet.pet}
                                </p>
                              </div>
                              <img src={Forward} className="w-[25px] h-[36px] cursor-pointer" onClick={handleNextPet} />
                            </div>
                          ) : level === 3 ? (
                            <div className="flex items-center justify-between w-full px-10">
                              <img src={Backward} className="w-[25px] h-[36px]" onClick={handlePreviousBehavior} />
                              <div className="flex flex-col items-center justify-center gap-5">
                                <img src={currentBehavior.image} alt={currentBehavior.behaviour} className="w-[205px] h-[198px]" />
                                <p className="text-center pb-4 font-[joystix] text-[18px] text-[#FFFFFF] text-outline">
                                  {currentBehavior.behaviour}
                                </p>
                              </div>
                              <img src={Forward} className="w-[25px] h-[36px]" onClick={handleNextBehavior} />
                            </div>
                          ) : (
                            <div className='w-[365px] h-[155px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                              <div className='w-[359.78px] h-[150px]  rounded-[6px] bg-[#918AAE]'>
                                <textarea
                                  onChange={(e) => setTypeOfPlaylist((e.target as HTMLTextAreaElement).value)}
                                  className='flex text-outline font-[joystix] text-start text-[#FFFFFF] text-[18px] w-[365px] h-[145px] bg-[#D4D3E1] rounded-[6px] border-[3.2px] border-[#918AAE] placeholder:text-[#FFFFFF] focus:border-transparent'
                                  style={{
                                    '--outline-width': '1.2px',
                                    '--outline-color': '#5A3D80',
                                    padding: '8px',
                                    resize: 'none'
                                  } as React.CSSProperties}
                                  placeholder='TYPE HERE'
                                />


                              </div>
                            </div>
                          )
                        }

                        <ButtonElement disabled={level === 4 ? !typeOfPlaylist : !petName} name={level === 4 ? "CREATE" : "NEXT"} onClick={handleNextLevel} />



                      </div>
                    </div>


                  </div> : <></>}

      </div>

      {/* {generatedPlaylist.length > 0 && (
        <div ref={createPlaylistRef} className='mt-8 text-center'>
          <h2 className='text-2xl font-bold text-white mb-4'>Generated Playlist</h2>
          <ul className='bg-white rounded-lg shadow-md p-4 mb-4'>
            {generatedPlaylist.map((song, index) => (
              <li key={index} className='text-gray-700'>{song}</li>
            ))}
          </ul>
          <button
            onClick={handleCreatePlaylist}
            className='bg-green-500 text-white font-bold py-2 px-4 rounded-md hover:bg-green-600 transition duration-300 flex items-center justify-center'
            disabled={isCreating}
          >
            {isCreating ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Creating...
              </>
            ) : (
              'Create Spotify Playlist'
            )}
          </button>
        </div>
      )}

      {playlistLink && (
        <div ref={iframeRef} className='mt-8 w-full flex flex-col items-center justify-center'>
          <h2 className='text-2xl font-bold text-white mb-4'>Your Spotify Playlist</h2>
          <iframe
            src={`https://open.spotify.com/embed/playlist/${playlistLink.split('/').pop()}`}
            width='300'
            height='380'
            frameBorder='0'
            allowTransparency={true}
            allow='encrypted-media'
            title='Spotify Playlist'
            style={{
              width: '75%',
            }}
          ></iframe>
        </div>
      )} */}
    </div>
  )
}

export default Dekstop