import React from 'react'

const ButtonElement = ({name, disabled, onClick}) => {
  return (
    <div className='w-[178px] h-[59px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
    <div className='w-[170px] h-[56px] justify-center flex rounded-b-[6px] bg-[#816D93]'> {/* Apply negative margin */}
      <div className='w-[166px] h-[53px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
        <button
          className='text-outline text-[29px] text-[#FFFFFF] w-[160px] h-[45px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#D4D3E1]'
          disabled={disabled}
          onClick={onClick}
          style={
            {
              '--outline-width': '1.2px',
              '--outline-color': '#5E5877'
            } 
          }
        >
          {name}
        </button>
      </div>
    </div>
  </div>
  )
}

export default ButtonElement